.generate-button {
    margin-left: 15px;
    margin-bottom: 15px;
}

.permission-column {
    text-align: center;
}

.spinner-border {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.table-col {
    height: 38px !important;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 18px;
}

.table-last-col {
    width: 80px !important;
}

.table-last-col-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}